import React from 'react'
import PropTypes from 'prop-types'
import { StyledBurger } from './styles'
import VisuallyHidden from '../shared/VisuallyHidden'

const Burger = ({ isOpen, onClick, ...rest }) => (
  <StyledBurger onClick={onClick} isOpen={isOpen} {...rest}>
    <span />
    <span />
    <span />
    <span />
    <VisuallyHidden>{isOpen ? 'Close' : 'Open'}</VisuallyHidden>
  </StyledBurger>
)

Burger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Burger
