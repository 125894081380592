import { useStaticQuery, graphql } from 'gatsby'

const useIndexPageQuery = () => {
  const {
    markdownRemark: {
      frontmatter: {
        title,
        slider,
        mainpitch,
        services,
        advantages,
        clients,
        certificates,
      },
      html,
    },
  } = useStaticQuery(graphql`
    query IndexPageQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        html
        frontmatter {
          title
          slider {
            heading
            description
            image {
              childImageSharp {
                fluid(quality: 50, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          services {
            servicesList {
              link {
                frontmatter {
                  title
                }
                fields {
                  slug
                }
              }
              image {
                childImageSharp {
                  fixed(width: 180) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
            heading
          }
          advantages {
            heading
            advantagesList {
              description
              icon {
                publicURL
              }
              title
            }
          }
          clients {
            heading
            clientsList {
              link
              name
              logo {
                publicURL
              }
            }
          }
          certificates {
            heading
            certificatesList {
              caption
              source {
                childImageSharp {
                  fluid(quality: 50, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return {
    title,
    slider,
    mainpitch,
    services,
    advantages,
    clients,
    html,
    certificates,
  }
}

export default useIndexPageQuery
