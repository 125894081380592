/* eslint-disable no-shadow */
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import {
  NavList,
  StyledMenuItem,
  NavLink,
  NavButton,
  Submenu,
  StyledNavigation,
} from './styles'

const MenuItem = ({ title, path, submenu }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <StyledMenuItem>
      {submenu ? (
        <NavButton as="button" onClick={() => setOpen(!isOpen)}>
          {title}
        </NavButton>
      ) : (
        <NavLink to={path}>{title}</NavLink>
      )}
      {submenu && (
        <Submenu isOpen={isOpen}>
          {submenu.map(({ title, path, submenu }) => (
            <MenuItem key={path} title={title} path={path} submenu={submenu} />
          ))}
        </Submenu>
      )}
    </StyledMenuItem>
  )
}

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  submenu: PropTypes.arrayOf(PropTypes.shape),
}

const Navigation = ({ links, ...rest }) => {
  return (
    <StyledNavigation {...rest}>
      <NavList>
        {links.map(({ title, path, submenu }) => (
          <MenuItem key={path} title={title} path={path} submenu={submenu} />
        ))}
      </NavList>
    </StyledNavigation>
  )
}

Navigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default Navigation
