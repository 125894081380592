import styled, { css } from 'styled-components'

export const StyledBurger = styled.button`
  z-index: 100;
  width: 30px;
  height: 22px;
  display: flex;
  color: inherit;
  background-color: transparent;
  border: none;
  outline: none;
  transform: rotate(0);
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: currentColor;
    opacity: 1;
    left: 0;
    transform: rotate(0);
    transition: 0.4s ease-in-out;
    transition-property: transform, width, top, left;
  }

  span:nth-child(1) {
    top: 0;
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: 10px;
  }
  span:nth-child(4) {
    top: 20px;
  }

  ${(p) =>
    p.isOpen &&
    css`
      span:nth-child(1),
      span:nth-child(4) {
        top: 10px;
        width: 0;
        left: 50%;
      }
      span:nth-child(2) {
        transform: rotate(45deg);
        background: currentColor;
      }
      span:nth-child(3) {
        transform: rotate(-45deg);
        background: currentColor;
      }
    `}
`
