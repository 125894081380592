import styled from 'styled-components'

export const StyledContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;

  @media (min-width: $md) {
    padding: 0 50px;
  }

  @media (min-width: $lg) {
    padding: 0 70px;
  }
`
