import React from 'react'
import Footer from '../Footer'
import Header from '../Header/Header'
import GlobalStyles from '../../styles/globalStyles'
import { StyledLayout, Main } from './styles'

const TemplateWrapper = ({ children }) => (
  <StyledLayout>
    <GlobalStyles />
    <Header />
    <Main>{children}</Main>
    <Footer />
  </StyledLayout>
)

export default TemplateWrapper
