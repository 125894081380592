import React from 'react'
import { Link } from 'gatsby'
import useContactsPageQuery from '../../hooks/useContactsPageQuery'
import {
  StyledFooter,
  FooterSection,
  FooterSectionTitle,
  FooterList,
  FooterItem,
  ListLink,
  FooterCopyright,
  DevLogo,
  Dev,
  FooterContent,
} from './styles'
import Container from '../shared/Container'
import Contact from '../shared/Contact'
import Socials from '../Socials'

const links = [
  {
    name: 'Про нас',
    link: '/about/',
  },
  {
    name: 'Новини',
    link: '/news',
  },
  {
    name: 'Контакти',
    link: '/contacts',
  },
  {
    name: `Зворотній зв'язок`,
    link: '/feedback',
  },
]

const Footer = () => {
  const { address, emails, phones, socials } = useContactsPageQuery()
  const contacts = [...phones.list, ...emails.list, ...address.list]

  return (
    <StyledFooter>
      <Container>
        <FooterContent>
          <FooterSection>
            <FooterSectionTitle>Контакти</FooterSectionTitle>
            <FooterList>
              {contacts.map(({ type, value }) => (
                <FooterItem key={value}>
                  <Contact type={type} value={value} />
                </FooterItem>
              ))}
            </FooterList>
          </FooterSection>
          <FooterSection>
            <FooterSectionTitle>Компанія</FooterSectionTitle>
            <FooterList>
              {links.map(({ name, link }) => (
                <FooterItem key={name}>
                  <ListLink as={Link} to={link}>
                    {name}
                  </ListLink>
                </FooterItem>
              ))}
            </FooterList>
          </FooterSection>
          <FooterSection>
            <FooterSectionTitle>Ми в соціальних мережах</FooterSectionTitle>
            <Socials socials={socials.list} />
          </FooterSection>
          <FooterCopyright>
            <span>
              © 2019
              <Dev
                href="https://www.linkedin.com/in/nick-naumenko/"
                target="_blank"
                rel="noopener"
              >
                {`by `}
                <DevLogo />
              </Dev>
            </span>
          </FooterCopyright>
        </FooterContent>
      </Container>
    </StyledFooter>
  )
}

export default Footer
