import styled from 'styled-components'

export const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  color: ${(props) => (props.invert ? '#fff' : 'var(--gray2)')};
  background-color: var(--green1);
  background-clip: content-box;

  &:hover {
    background-color: transparent;
    border: 2px solid var(--green1);
    color: var(--green1);
  }
`

export const Icon = styled.span`
  fill: currentColor;
  width: 100%;
  height: 100%;
  width: 20px;
  max-height: 30px;
`
