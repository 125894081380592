import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  :root {
    /* variables */
    --font: 16px;
  
    --white1: #dfdfdf;
    /* --white2: rgba(255, 255, 255, 0.16); */
    --white2: #fdfdfd;

    --green1: #92BB22;
    --green2: #0d6526;

    --gray1: #f5f5f5;
    --gray2: #44514E;
    --gray3: #ffffff29;
    --gray4: #b8b7b7;

    --shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
    /* variables */    
  }

  html {
    height: -webkit-fill-available;
    -webkit-text-size-adjust: 100%;
  }

  body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    margin: 0;
    font-family: "Museo",Helvetica,Arial,sans-serif;
    font-size: var(--font);
    line-height: 1.3;
    color: var(--gray2);
    background-color: var(--white2);
    text-align: justify;
  }

  h1 {
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;

    @media (min-width: 768px) {
      font-size: 2.5rem;
    }

    @media (min-width: 992px) {
      font-size: 3rem;
    }
  }

  h2 {
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 700;

    @media (min-width: 768px) {
      font-size: 2.5rem;
    }

    @media (min-width: 992px) {
      font-size: 2.8rem;
    }
  }

  h3 {
    font-size: 1.7rem;
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    text-transform: uppercase;


    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }

  h4 {
    font-size: 1.5rem;
    margin-top: 0.8em;
    margin-bottom: 0.8em;
    text-transform: uppercase;
  }

  @media (max-width: 768px) {
    :root {
      --font: 14px;
    }

    h1 {
      font-size: 1.5rem;
    }

    h4 {
      font-size: 1rem;
    }
  }
`

export default GlobalStyles
