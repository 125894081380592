import { useStaticQuery, graphql } from 'gatsby'

const useContactsPageQuery = () => {
  const {
    markdownRemark: {
      frontmatter: {
        title,
        description,
        contacts: { schedule, address, emails, phones, additionals, socials },
      },
    },
  } = useStaticQuery(graphql`
    query ContactsPageQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "contacts-page" } }) {
        frontmatter {
          title
          description
          contacts {
            address {
              title
              list {
                type
                value
              }
            }
            schedule
            additionals {
              name
              value
            }
            emails {
              title
              list {
                type
                value
              }
            }
            phones {
              title
              list {
                value
                name
                type
              }
            }
            socials {
              title
              list {
                link
                type
              }
            }
          }
        }
      }
    }
  `)

  return {
    title,
    description,
    schedule,
    address,
    emails,
    phones,
    additionals,
    socials,
  }
}

export default useContactsPageQuery
