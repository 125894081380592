import React from 'react'
import FacebookIcon from '../../img/social/icon-fb.inline.svg'
import TwitterIcon from '../../img/social/icon-tw.inline.svg'
import LinkedinIcon from '../../img/social/icon-li.inline.svg'
import TelegramIcon from '../../img/social/icon-tg.inline.svg'
import ViberIcon from '../../img/social/icon-viber.inline.svg'
import InstagramIcon from '../../img/social/icon-instagram.inline.svg'
import { Link, Icon } from './styles'
import VisuallyHidden from '../shared/VisuallyHidden/VisuallyHidden'

const SocialLink = ({ type, link, invert }) => {
  const getIcon = (iconType) => {
    switch (iconType) {
      case 'facebook':
        return <Icon as={FacebookIcon} />
      case 'twitter':
        return <Icon as={TwitterIcon} />
      case 'linkedin':
        return <Icon as={LinkedinIcon} />
      case 'telegram':
        return <Icon as={TelegramIcon} />
      case 'viber':
        return <Icon as={ViberIcon} />
      case 'instagram':
        return <Icon as={InstagramIcon} />
      default:
        return null
    }
  }

  return (
    <Link invert={invert} href={link} target="_blank" rel="noopener">
      {getIcon(type)}
      <VisuallyHidden>{type}</VisuallyHidden>
    </Link>
  )
}

export default SocialLink
