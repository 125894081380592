import React, { useState } from 'react'
import { Link } from 'gatsby'
import Container from '../shared/Container'
import Navigation from '../Navigation'
import { StyledHeader, HeaderContent, HeaderBurger } from './styles'
import useIndexPageQuery from '../../hooks/useIndexPageQuery'
import Logo from '../Logo'
import VisuallyHidden from '../shared/VisuallyHidden'

const staticNavLinks = [
  {
    title: 'Про компанію',
    path: '/about',
  },
  {
    title: 'Новини',
    path: '/news',
  },
  {
    title: 'Контакти',
    path: '/contacts',
  },
]

const Header = () => {
  const {
    services: { servicesList },
  } = useIndexPageQuery()

  const servicesLinks = servicesList.map(
    ({
      link: {
        fields: { slug: path },
        frontmatter: { title },
      },
    }) => ({ title, path })
  )

  const navLinks = [
    { title: 'Послуги', path: '/services', submenu: servicesLinks },
    ...staticNavLinks,
  ]

  const [isOpen, setOpen] = useState(false)

  const toggleMenu = () => setOpen(!isOpen)

  return (
    <>
      <StyledHeader isOpen={isOpen}>
        <Container>
          <HeaderContent>
            <Link to="/">
              <Logo />
              <VisuallyHidden>Home</VisuallyHidden>
            </Link>
            <Navigation links={navLinks} isOpen={isOpen} />
            <HeaderBurger isOpen={isOpen} onClick={toggleMenu} />
          </HeaderContent>
        </Container>
      </StyledHeader>
    </>
  )
}

export default Header
