import styled from 'styled-components'

export const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  min-height: -webkit-fill-available;
`

export const Main = styled.main`
  padding-top: 85px;
`
