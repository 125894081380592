import styled from 'styled-components'
import Burger from '../Burger/Burger'

export const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  background-color: var(--white2);
  color: inherit;
  box-shadow: var(--shadow);
`

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 85px;
`

export const HeaderBurger = styled(Burger)`
  align-self: center;
  @media (min-width: 992px) {
    display: none;
  }
`
