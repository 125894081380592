import styled from 'styled-components'
import NickLogo from '../../img/logo-nick.inline.svg'

export const StyledFooter = styled.footer`
  padding: 2rem 0;
  color: #fff;
  background-color: var(--gray2);
`

export const FooterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -8px;

  @media (min-width: 768px) {
    margin: 0 -16px;
  }
`

export const FooterSection = styled.section`
  box-sizing: border-box;
  width: 100%;
  padding: 16px 8px;

  @media (min-width: 768px) {
    width: 50%;
    max-width: 450px;
    padding: 16px;
  }

  @media (min-width: 1200px) {
    width: calc(100% / 3);
  }
`

export const FooterSectionTitle = styled.h4`
  position: relative;
  padding-bottom: 0.5em;
  font-weight: 400;

  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    display: block;
    height: 2px;
  }

  &::before {
    left: 0;
    width: 50px;
    background-color: var(--green1);
  }

  &::after {
    right: 0;
    width: calc(100% - 60px);
    background-color: var(--gray3);
  }
`

export const FooterList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

export const FooterItem = styled.li`
  margin-bottom: 0.5em;

  &:last-child {
    margin-bottom: 0;
  }
`

export const FooterLink = styled.a`
  display: flex;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  padding-bottom: 5px;

  &:hover {
    color: var(--green1);
  }
`

export const ListLink = styled(FooterLink)`
  &::before {
    content: '';
    display: block;
    align-self: center;
    height: 2px;
    width: 15px;
    margin-right: 10px;
    background-color: var(--green1);
  }
`

export const FooterCopyright = styled(FooterSection)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: var(--gray3);

  @media (min-width: 1200px) {
    align-self: flex-end;
    margin-left: auto;
    margin-top: -62px;
  }
`

export const Dev = styled(FooterLink)`
  position: relative;
  display: inline-block;
  width: 45px;
  height: 30px;
  margin-left: 30px;
`

export const DevLogo = styled(NickLogo)`
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 30px;
  color: inherit;
`
