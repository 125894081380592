import styled, { keyframes } from 'styled-components'
import { Link } from 'gatsby'

export const StyledNavigation = styled.nav`
  @media (max-width: 991px) {
    position: absolute;
    top: 80px;
    left: 0;
    display: flex;
    width: 100%;
    color: var(--gray2);
    transition: transform 0.3s;
    transform: ${({ isOpen }) =>
      isOpen ? 'translateX(0)' : 'translateX(-100%)'};
  }
`

export const NavList = styled.ul`
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: calc(100vh - 80px);
    padding: 1.5em;
    background-color: #fff;
    overflow-y: auto;
  }
`

export const StyledMenuItem = styled.li`
  position: relative;
  width: 100%;
`

export const NavLink = styled(Link)`
  display: inline-block;
  max-width: 550px;
  padding: 0.5em;
  font: inherit;
  color: inherit;
  font-size: 0.875rem;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  white-space: nowrap;
  font-weight: 700;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    color: var(--green1);
  }

  @media (max-width: 991px) {
    width: 100%;
    max-width: none;
    margin-bottom: 1em;
    font-size: 1rem;
    white-space: normal;
  }
`
export const NavButton = styled(NavLink)`
  display: flex;
  justify-content: space-between;

  &::after {
    content: '+';
    display: none;
  }

  @media (max-width: 991px) {
    &::after {
      display: inline-block;
    }
  }
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const Submenu = styled.ul`
  right: 0;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #fff;

  ${NavLink} {
    padding-left: 1em;
    font-weight: 400;
    text-transform: capitalize;
  }

  @media (min-width: 992px) {
    position: absolute;
    padding: 1em;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

    ${StyledMenuItem}:hover & {
      display: block;
    }

    ${NavLink} {
      display: block;
      min-width: 460px;
      margin-bottom: 5px;
      white-space: normal;
      color: var(--gray2);
      animation: ${fadeIn} 0.4s ease-in both;

      &:hover {
        color: var(--green1);
      }
    }
  }
`
