import React from 'react'
import SocialLink from '../SocialLink'
import { List, Item } from './styles'

const Socials = ({ socials, invert }) => (
  <List>
    {socials.map(({ type, link }) => (
      <Item key={type}>
        <SocialLink invert={invert} type={type} link={link} />
      </Item>
    ))}
  </List>
)

export default Socials
