import styled from 'styled-components'

import PhoneIcon from '../../../img/icon-phone.inline.svg'
import EmailIcon from '../../../img/icon-mail.inline.svg'
import AddressIcon from '../../../img/icon-address.inline.svg'

export const ContactLink = styled.a`
  display: flex;
  padding-bottom: 5px;
  color: inherit;
  text-decoration: none;

  &:hover {
    color: var(--green1);
  }

  /* @media (m) */
`

export const StyledPhoneIcon = styled(PhoneIcon)`
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  align-self: center;
  margin-right: 0.5em;
  fill: var(--green1);
  color: var(--green1);
`

export const StyledEmailIcon = styled(EmailIcon)`
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  align-self: center;
  margin-right: 0.5em;
  fill: var(--green1);
  color: var(--green1);
`
export const StyledAddressIcon = styled(AddressIcon)`
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  align-self: center;
  margin-right: 0.5em;
  fill: var(--green1);
  color: var(--green1);
`
