import React from 'react'
import {
  ContactLink,
  StyledPhoneIcon,
  StyledEmailIcon,
  StyledAddressIcon,
} from './styles'
import { formatPhone } from '../../../utils/formatPhone'

const PhoneLink = ({ value, ...rest }) => (
  <ContactLink href={`tel:${value}`} {...rest}>
    <StyledPhoneIcon />
    <span>{formatPhone(value)}</span>
  </ContactLink>
)

const EmailLink = ({ value, ...rest }) => (
  <ContactLink href={`mailto:${value}`} {...rest}>
    <StyledEmailIcon />
    <span>{value}</span>
  </ContactLink>
)

const AddressLink = ({ value, ...rest }) => (
  <ContactLink as="span" {...rest}>
    <StyledAddressIcon />
    <span>{value}</span>
  </ContactLink>
)

const Contact = ({ type, value: contactValue, ...rest }) => {
  const types = {
    phone: (value) => <PhoneLink value={value} {...rest} />,
    email: (value) => <EmailLink value={value} {...rest} />,
    address: (value) => <AddressLink value={value} {...rest} />,
  }

  return types[type](contactValue)
}

export default Contact
